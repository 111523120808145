html {
  overflow-x: hidden;
}

// Hide volume slider, use device volume instead.
/*
audio::-webkit-media-controls-mute-button {
  display: none;
}

audio::-webkit-media-controls-volume-slider {
  display: none;
}
*/

audio::-webkit-media-controls-volume-control-container.closed {
  display: none;
}

audio::-webkit-media-controls-volume-control-container {
  display: none;
}
